















































import { Component, Mixins } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';
import { userService } from '@/services';
import CommonEnum from '@/enums/CommonEnum';

@Component({
  components: {
  },
})
export default class LoginPage extends Mixins(CommonMixin) {
  private get ResponseCodeEnum() {
    return CommonEnum.ResponseCodeEnum;
  }
  private password: string = '';
  private usernameOrEmail: string = '';
  private isAlertError: boolean = false;

  private login() {
    this.isAlertError = false;
    this.$store.dispatch('isLoading', true);
    userService.logIn(this.usernameOrEmail, this.password).then((res: any) => {
      this.axios.prototype.setUserInfo(res.data.token);
      userService.getProfile().then((res2: any) => {
        if (res2.data.code === this.ResponseCodeEnum.CODE_SUCCESS) {
          this.$store.dispatch('setUser', res2.data.data);

          localStorage.setItem('Language', res2.data.data.language);
          this.axios.defaults.headers.common.Language = res2.data.data.language;
          this.$store.dispatch('setLang', res2.data.data.language);

          if (this.$route.path === '/login') {
            this.$router.push({ path: '/' });
          } else {
            this.$router.go(-1);
          }
        }
      });
    })
    .catch((err: any) => {
      this.isAlertError = true;
      this.axios.prototype.deleteUserInfo();
    })
    .finally(() => this.$store.dispatch('isLoading', false));
  }

  private mounted() {
    this.$nextTick(() => {
      const linkLogin = document.getElementById('linkForgot');
      if (linkLogin) {
        linkLogin.addEventListener('click', this.toPageForgot);
      }
    });
  }

  private toPageForgot(event: Event) {
    event.preventDefault();
    this.$router.push({ path: '/forgot-password' });
  }
}
