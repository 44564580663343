



















































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';
import { userService } from '@/services';
import { User } from '@/models';
import CommonEnum from '@/enums/CommonEnum';
import { validationUtils } from '@/validation/ValidationUtils';
import { InputError } from '@/validation/InputError';

@Component({
  components: {
  },
})
export default class ProfilePage extends Mixins(CommonMixin) {
  private get HttpStatus() {
    return CommonEnum.HttpStatus;
  }
  private get ResponseCodeEnum() {
    return CommonEnum.ResponseCodeEnum;
  }
  private validationUtils = validationUtils;
  private errors: InputError[] = [];
  private isAlertUpdateNickNameSuccess = false;
  private isAlertUpdateEmailSuccess = false;
  private isAlertUpdatePasswordSuccess = false;
  private isAlertUpdatePhoneNumberSuccess = false;

  private user: User = new User();
  get userStore(): User {
    return this.$store.state.user;
  }

  private async created() {
    this.errors = validationUtils.initErrors(this.user);
    await this.getUserProfile();
  }

  private async getUserProfile() {
    this.$store.dispatch('isLoading', true);
    this.axios.prototype.loadHeaderInfo();
    if (this.axios.prototype.hasUserInfo()) {
      await userService.getProfile().then((res: any) => {
        if (res.data.code === this.ResponseCodeEnum.CODE_SUCCESS) {
          this.user = res.data.data;
          this.$store.dispatch('setUser', res.data.data);
        }
      })
      .finally(() => this.$store.dispatch('isLoading', false));
    }
  }

  private formatSTP(value: number) {
    return value.toFixed(2);
  }

  private updateProfile(field: string) {
    validationUtils.clearError(this.errors);
    this.isAlertUpdateNickNameSuccess = false;
    this.isAlertUpdateEmailSuccess = false;
    this.isAlertUpdatePasswordSuccess = false;
    this.isAlertUpdatePhoneNumberSuccess = false;
    this.$store.dispatch('isLoading', true);
    userService.updateProfile(this.user, field).then((res: any) => {
      if (res.status === this.HttpStatus.OK) {
        if (field === 'nickName') {
          this.isAlertUpdateNickNameSuccess = true;
        }
        if (field === 'email') {
          this.isAlertUpdateEmailSuccess = true;
        }
        if (field === 'password') {
          this.isAlertUpdatePasswordSuccess = true;
        }
        if (field === 'phoneNumber') {
          this.isAlertUpdatePhoneNumberSuccess = true;
        }
        this.getUserProfile();
      }
    })
    .catch((error: any) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.violations
      ) {
        validationUtils.loadFieldErrors(
          error.response.data.violations,
          this.errors,
        );
        if (
          this.errors.filter((e: any) => e.isError === false) &&
          this.errors.filter((e: any) => e.isError === false)[0].field
        ) {
          (this.$refs[this.errors.filter((e: any) => e.isError === false)[0].field as string] as any).focus();
        }
      }
    }).finally(() => this.$store.dispatch('isLoading', false));
  }
}
