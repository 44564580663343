
































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator';

import {userService} from '@/services';
import { User } from '@/models';
import {validationUtils} from '@/validation/ValidationUtils';
import CommonEnum from '@/enums/CommonEnum';
import {InputError} from '@/validation/InputError';
import CommonMixin from '@/components/CommonMixin';
import Cookies from 'js-cookie';
import $ from 'jquery';
import 'daterangepicker';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { dateUtils } from '@/utils/DateUtils';

@Component({
  components: {
    Datepicker,
  },
})
export default class RegisterPage extends Mixins(CommonMixin) {
  private get HttpStatus() {
    return CommonEnum.HttpStatus;
  }
  private validationUtils = validationUtils;
  private errors: InputError[] = [];
  private isAlertError = false;
  private isAlertSuccess = false;
  private $bvModal: any;

  private user: User = new User();

  private mounted() {
    $('#birthday').on('blur', this.test);
  }

  private test() {
    if (this.user.birthday) {
      ($('#birthday') as any)[0].value = dateUtils.formatDateTime(this.user.birthday!, 'YYYY-MM-DD');
      ($('#birthday') as any)[0]._value = dateUtils.formatDateTime(this.user.birthday!, 'YYYY-MM-DD');
    }
  }

  private created() {
    if (!(this.$route.query.ref === null || this.$route.query.ref === undefined)) {
      Cookies.set('register-affiliate-id', this.$route.query.ref);
    }
    this.errors = validationUtils.initErrors(this.user);
  }

  private register() {
    validationUtils.clearError(this.errors);
    this.isAlertError = false;
    this.isAlertSuccess = false;
    this.$store.dispatch('isLoading', true);
    if (
      !this.user.isAge ||
      !this.user.isPolicy
    ) {
      this.$store.dispatch('isLoading', false);
      this.$bvModal.show('bv-modal-example');
      return;
    }
    this.axios.prototype.loadHeaderInfo();
    userService.register(this.user).then((res: any) => {
      if (res.status === this.HttpStatus.OK) {
        this.isAlertSuccess = true;
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    })
    .catch((error: any) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.violations
      ) {
        validationUtils.loadFieldErrors(
          error.response.data.violations,
          this.errors,
        );
        if (
          this.errors.filter((e: any) => e.isError === false) &&
          this.errors.filter((e: any) => e.isError === false)[0].field
        ) {
          (this.$refs[this.errors.filter((e: any) => e.isError === false)[0].field as string] as any).focus();
        }
      }
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        this.isAlertError = true;
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }).finally(() => this.$store.dispatch('isLoading', false));
  }
}
