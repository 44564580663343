


















































































import { Component, Mixins } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';
import { epoWalletService } from '@/services';
import { DepositEPOWallet } from '@/models';
import CommonEnum from '@/enums/CommonEnum';

@Component({
  components: {
  },
})
export default class DepositEPOWalletPage extends Mixins(CommonMixin) {
  private get ResponseCodeEnum() {
    return CommonEnum.ResponseCodeEnum;
  }
  private depositEPOWallet: DepositEPOWallet = new DepositEPOWallet();
  private isMsgError: boolean = false;
  private msgError: string = '';
  private isMsgErrorA: boolean = false;
  private step: number = 1;

  private mounted() {
    this.depositEPOWallet.token = this.$route.query.token.toString();
  }

  private deposit() {
    this.isMsgErrorA = false;
    this.msgError = '';
    if (this.depositEPOWallet.amount) {
      if (this.depositEPOWallet.amount < 30 || this.depositEPOWallet.amount > 3000) {
        this.isMsgErrorA = true;
        if (this.depositEPOWallet.amount < 1) {
          this.msgError = 'deposit_epo_wallet_page.msg.error.amount_is_empty';
        } else {
          this.msgError = 'deposit_epo_wallet_page.msg.error.amount_between';
        }
      } else {
        this.step = 2;
      }
    } else {
      this.isMsgErrorA = true;
      this.msgError = 'deposit_epo_wallet_page.msg.error.amount_is_empty';
    }
  }

  private confirmDeposit() {
    this.isMsgError = false;
    if (
      this.depositEPOWallet.amount &&
      (this.depositEPOWallet.amount > 0 && this.depositEPOWallet.amount <= 3000)
    ) {
      this.$store.dispatch('isLoading', true);
      epoWalletService.deposit(this.depositEPOWallet).then((res: any) => {
        if (res.data.code === this.ResponseCodeEnum.CODE_SUCCESS && res.data.data === true) {
          this.$router.push({
            name: 'user-transaction-transaction-history',
          });
        } else {
          this.isMsgError = true;
        }
      })
      .catch(() => this.isMsgError = true)
      .finally(() => this.$store.dispatch('isLoading', false));
    }
  }

  private backToTransactionMenu() {
    this.$router.push({
      name: 'user-transaction-transaction-menu',
    });
  }
}
