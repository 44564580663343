import { Vue, Component } from 'vue-property-decorator';
import Loading from '@/components/Loading.vue';

@Component({
  components: {
    loading: Loading,
  },
})
export default class CommonMixin extends Vue {
}
